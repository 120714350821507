<template>
  <div>
    <div>

      <div class="misc-inner p-2 p-sm-3 bg-orange">

        <div class="w-100 text-center">
          <b-img
            fluid
            :src="require('@/assets/images/logo/bisevowhite.png')"
            alt="logo img"
            width="150px"
            height="60px"
          />

        </div>

        <div class="w-100 text-center">
          <h3 class="mb-1 page-heading-2">
            Welcome to {{ game.gameName }}  Gamification
          </h3>

        </div>

        <div class="w-100 text-center">
          <p
            class="mb-1 page-description-samll"
            v-html="$t('DOWNLOAD_YOUR_APP_HERE')"
          />
          <b-link
            target="_blank"
            :href="appUrl.android"
          >
            <b-img
              fluid
              :src="require('@/assets/images/pages/google_badge.svg')"
              width="150px"
              height="60px"
              class="center-img"
              alt="landing page"
            />
          </b-link>

          <b-link
            target="_blank"
            :href="appUrl.ios"
          >
            <b-img
              fluid
              :src="require('@/assets/images/pages/ios_badge.svg')"
              width="136px"
              height="60px"
              alt="landing page"
            />
          </b-link>

        </div>

        <br>

        <div class="w-100 text-center img-container">
          <!-- img -->
          <b-img
            fluid
            class="center-img"
            :src="require('@/assets/images/pages/Award-img-1.png')"
            alt="landing page"
          />

        </div>

      </div>
    <!-- /Main Page-->
    </div>
    <b-row>
      <b-col class="col-md-4 col-first" />
      <b-col class="col-md-4 pt-3 col-second">
        <div class="w-100 text-center pb-2 pt-5" />

        <h1 class="big-title text-center w-100">
          <strong>Happy gaming !</strong>
        </h1>

      </b-col>

    </b-row>
    <div class="about" />
    <div>
      <div>
        <b-row>
          <b-col
            lg="10"
            offset-lg="3"
            class="mx-auto"
          >
            <div />
          </b-col>
        </b-row>
      </div>
    </div>

  </div>

</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BImg, BRow, BCol,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import router from '@/router'
import { mixinList } from '@/constants/mixinValidations'
import constants from '@/constants/static.json'

export default {
  components: {
    BLink,
    BImg,
    BRow,
    BCol,
  },
  mixins: [mixinList],
  data() {
    return {
      game: {
        gameName: constants.COMPANY_NAME,
        startDate: '',
        endDate: '',
        visionStatement: '',
      },
      appUrl: {
        android: constants.APP_URL.ANDROID,
        ios: constants.APP_URL.IOS,
        webGl: constants.APP_URL.WEB_GL,
      },
      isGame: false,
    }
  },
  created() {
    this.fetchGameByID()
  },
  methods: {
    ...mapActions('onboarding', ['fetchGame']),
    fetchGameByID() {
      if (router.currentRoute.params.id) {
        this.fetchGame(router.currentRoute.params.id).then(response => {
          this.isGame = true
          this.game.gameName = response.c_string_swe
          this.game.startDate = response.startdate
          this.game.endDate = response.enddate
          this.game.visionStatement = response.visionstatement
        })
      }
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
.img {
  max-width: 40%;
  margin-top: -20px;
}
@media only screen and (min-width: 1300px) {
  .img {
  max-width: 50%;
  margin-top: -20px;
}
}
@media only screen and (max-width: 1300px) {
  .img {
  max-width: 80%;
  margin-top: -20px;
}
}
@media only screen and (max-width: 700px){
  .col-first {
    display: none;
  }
  .col-third {
    display: none;
  }
  .col-second {
    padding: 0px 25px;
  }
}
@media only screen and (min-width: 1500px) {
  td {
    font-size: 22px;
  }
}
@media only screen and (max-width: 1500px) {
  .big-title {
    margin-top: -60px;
  }
}

</style>
